
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.feasts {

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  &__item {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    background: $color-grayscale-05;
    border-radius: 12px;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    flex-grow: 1;
    margin-bottom: 6px;
    transition: opacity $base-animation-time;

    &--selected {
      background-color: rgba(148, 102, 255, 0.1);
    }

    @include hover(true) {
      opacity: .8;
    }
  }
}

.feast {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &__title {
    margin-bottom: 24px;
  }

  &__images {
    margin-bottom: 24px;
    width: 100%;
  }

  &__image {
    /*height: 200px;*/
    border-radius: $base-border-radius;
    border: 2px solid $color-client-portal-logo-op-60;
    overflow: hidden;
    transition: border-color $base-animation-time;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
    @include hover(true) {
      border-color: $color-primary-1-day;
    }
    &--selected {
      border-color: $color-primary-1-day;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $color-client-portal-logo-op-60;
        opacity: .5;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__button {
    width: 27px;
    height: 44px;
    &::after {
      content: '';
      color: $color-client-portal-logo;
      width: 27px;
      height: 27px;
      border-top: 5px solid #6E18C0;
      border-right: 5px solid #6E18C0;
    }
    &--prev {
      &::after {
        transform: rotate(-135deg);
      }
    }
    &--next {
      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__checkbox {
    margin-bottom: 24px;
  }

  &__message {
    width: 100%;
    font-weight: 400;
    font-family: $base-font-family;
    color: $color-text;
    margin-bottom: 24px;
    border: none;
    outline: none;
    min-height: 48px;
    font-feature-settings: "tnum", "lnum";
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
    border-radius: $base-border-radius;
    background-color: $color-black-op-05;

    &::placeholder {
      color: $color-black-op-75;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: "tnum", "lnum";
    }
  }

  .search-user {
    margin-bottom: 24px;
  }
}
