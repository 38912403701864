@import '~@esmpfrontend/ui/src/assets/scss/utils.scss';

@mixin hover($pointer: false) {
  @media (hover: hover) {
    @if ($pointer) {
      cursor: pointer;
    }

    &:hover {
      @content;
    }
  }
}

